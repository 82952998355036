<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
  <section>
    <b-container>
      <b-row>
        <b-col md="12" class="mx-auto">
          <div class="question">
            <b-card>
              <b-card-body>
                <div class="question mb-4" v-for = "(answer, index) in answers">
                  <h4 class="mb-4 d-flex" v-if = "answer.question"><span class="mr-1">Q{{index+1}}</span>{{answer.question.question}}</h4>
                  <b-list-group class="mb-2">
                    <b-list-group-item v-if = "answer.question" :variant="answer.question.answer == 1 ? 'success': ''">{{answer.question.option_1}}</b-list-group-item>
                    <b-list-group-item v-if = "answer.question" :variant="answer.question.answer == 2 ? 'success': ''">{{answer.question.option_2}}</b-list-group-item>
                    <b-list-group-item v-if = "answer.question" :variant="answer.question.answer == 3 ? 'success': ''">{{answer.question.option_3}}</b-list-group-item>
                    <b-list-group-item v-if = "answer.question" :variant="answer.question.answer == 4 ? 'success': ''">{{answer.question.option_4}}</b-list-group-item>
                  </b-list-group>
                  <p v-if="answer.question.answer == answer.answer">Your Answer is <span class="badge badge-success">Correct</span></p>
                  <p v-else>Your Answer is <span class="badge badge-danger">Incorrect</span></p>
                </div>
                <!--
                <div class="question mb-4">
                  <h4 class="mb-4 d-flex"><span class="mr-1">Q2</span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel assumenda ab neque corrupti? Voluptatum ut illum, necessitatibus commodi, rerum numquam eveniet, cum molestias dolores rem omnis? Nam voluptatibus vel harum?</h4>
                  <b-list-group>
                    <b-list-group-item>Option 1</b-list-group-item>
                    <b-list-group-item variant="success">Option 2</b-list-group-item>
                    <b-list-group-item>Option 3</b-list-group-item>
                    <b-list-group-item variant="danger">Option 4</b-list-group-item>
                  </b-list-group>
                </div>
                -->
              </b-card-body>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  </b-overlay>
</template>

<style scoped>
    .question-radio input[type="radio"]{
    display: none;
}
.question-radio label {
    box-shadow: 0px 2px 11px rgb(69 65 164 / 6%), 0px 4px 10px rgb(31 37 89 / 7%);
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #6610f2;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
}
.question-radio label .option-count {
    border-radius: 50%;
    box-shadow: 0px 2px 11px rgba(69, 65, 164, 0.06), 0px 4px 10px rgba(31, 37, 89, 0.07);
    width: 40px;
    height: 40px;
    border: 1px solid #ddd;
    line-height: 40px;
    text-align: center;
}
.question-radio input:checked + label {
    background-color: #7367f0;
    color: #ffffff !important;
}
.question-collapse .card-header{
    cursor: pointer;
}
.rotate-icon{
    float:right;
  }
  .question-collapse .card-header:not(.not-collapsed) .rotate-icon{
    transform: rotate(180deg);
  }
</style>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import MultipleQuestionService from '@/services/multipleQuestion.service.js';
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BTable, BProgress,BButton, BContainer, BListGroup, BListGroupItem, BRow, BCol, BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BLink, BIcon,BCard,BCardHeader,BCardBody,VBToggle,BCollapse} from 'bootstrap-vue'
  export default {
    components: {
        BCardCode,
        BTable,
        BProgress,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BOverlay,
        BRow,
        BCol,
        BButton,
        BLink,
        BIcon,
        BContainer,
        BCard,
        BCardHeader,
        BCardBody,
        BCollapse,
        BListGroup,
        BListGroupItem
    },
    directives: {
        'b-toggle': VBToggle,
    },
    data() {
      return {
        id: this.$route.params.id,
        result: {},
        answers: {},
        question: {},
        showOverlay: false, 
      }
    },
     watch:{
        '$route'(to) {
            this.id = to.params.id;
        } 
    },
    methods: {
      findResult()
      {
        this.showOverlay = true;
        console.log(this.id)
        MultipleQuestionService.findAnswer(this.id).then(response => {
          this.showOverlay = false;
          this.result = response.data.data.result
          if(this.result.quiz_answers && this.result.quiz_answers.length > 0) {
            this.answers = this.result.quiz_answers;
          }
          console.log(response.data.data)
        }).catch(error => {
          this.showOverlay = false;
          this.errors = error.response
        });
      }
    },
    created ()
    {
        this.findResult()
    }
  }
</script>